@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');


body {
  font-family:Poppins;
  margin: 0;
  background-color: #ffffff;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='160' height='160' viewBox='0 0 200 200'%3E%3Cpolygon fill='%23DCEFFA' points='100 0 0 100 100 100 100 200 200 100 200 0'/%3E%3C/svg%3E");

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.button{
  background-color: #60a5fa;
 border: 3px solid black;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  width: 180px;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}



.button:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
  background-color:#93c5fd;
}

.numBtn{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.element {
  opacity: 0; /* Initial opacity set to 0 */
  animation: zoomInOpacity 0.7s ease-in-out forwards; /* Apply the animation */
}
@media (max-width:650px) {

  .button{
    font-size: 16px;
    width:170px;
    padding: 8px;
  }
  
}

@keyframes zoomInOpacity {
  0% {
    opacity: 0;
    transform: scale(0.5); /* Start from a smaller size */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* End at normal size */
  }
}