body.active-modal {
    overflow-y: hidden;
  }
  
  .modal,
  .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
  }
  
  .overlay {
    background: rgba(49, 49, 49, 0.8);
  }
  .modal-content {
    
   display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color:#b6cfdd;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    border: 5px solid black;
    border-radius: 15px;
    
  }
  

  button{
    margin-left: auto;
    margin-right: auto
  }
  
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }